import React, { useRef } from 'react';
import { graphql, navigate } from 'gatsby';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import SEO from '../components/seo';
import { Section } from '../components/page-elements';
import Container from '../components/container';
import BottomCut from '../components/bottomCut';
import Button from '../components/buttons';
import CTABlock from '../components/ctaBlock';
import CenteredHeaderBlock from '../components/centeredHeaderBlock';
import Spacer from '../components/spacer';
import { PreHeader, ButtonText, P1 } from '../theme/typography';
import FormInput2 from '../components/formInput2';
import AnimatedStripe from '../components/animatedStripe';

// Asset Imports
import Stripes05SVG from '../images/stripes/stripes05.inline.svg';
import Stripes03SVG from '../images/stripes/stripes03.inline.svg';
import { GatsbyImage } from 'gatsby-plugin-image';

//Styled Components
const FormWrapper = styled.div`
  width: 100%;
  max-width: 800px;
  z-index: 1;
  position: relative;
  overflow: visible;
  margin: 0 auto;
`;

const FormTile = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 48px 32px 64px 32px;
  background: var(--blue6);
  border-radius: 8px;
  box-shadow: var(--box-shadow-xl);
`;

const FormTitle = styled(PreHeader)`
  margin: 0 auto 32px auto;
`;

const FormStripes01 = styled(AnimatedStripe)`
  position: absolute;
  width: 500px;
  height: 350px;
  top: -30px;
  left: -200px;
  z-index: -1;

  @media (min-width: 1024px) {
    width: 450px;
    height: 300px;
    top: -30px;
    left: -80px;
  }

  @media (min-width: 1300px) {
    left: -100px;
    top: -70px;
    width: 350px;
    height: 350px;
  }
`;

const FormStripes02 = styled(AnimatedStripe)`
  position: absolute;
  width: 70%;
  height: 20%;
  bottom: -5%;
  right: -25%;
  z-index: -1;
`;

const DirectorLabel = styled(ButtonText)`
  margin-bottom: 4px;
  color: var(--blue2);
`;

const DirectorTile = styled.div`
  background: var(--white);
  width: 100%;
  border-radius: 8px;
  padding: 16px 24px;
  box-shadow: var(--box-shadow-md);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
`;

const DirectorHeadshot = styled(GatsbyImage)`
  border-radius: 50%;
  width: 64px;
  aspect-ratio: 1 / 1;
  filter: grayscale();
`;

const DirectorName = styled(P1)``;

const SendMessageButton = styled(Button)`
  width: 100%;

  &:disabled {
    background-color: var(--blue4);
    opacity: 0.6;
    cursor: unset;

    &:hover {
      transform: none;
      box-shadow: none;
    }
  }
`;

const validationSchema = Yup.object().shape({
  director: Yup.string(),
  name: Yup.string()
    .min(2, `C'mon your name is longer than that!`)
    .required(`Required`),
  email: Yup.string().email(`Must be a valid email.`).required(`Required`),
  phone: Yup.string()
    .matches(
      /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/,
      `Must be a valid phone number.`,
    )
    .required(`Required`),
  message: Yup.string(),
});

const WorkWithDirectorPage = ({ data, location }) => {
  const formEl = useRef(null);
  const isDirectorChosen =
    location.state != null && location.state.directorSlug != null;

  let chosenDirector;

  if (isDirectorChosen == true) {
    chosenDirector = data.allSanityDirector.nodes.find(
      (director) =>
        director.slug.current == location.state.directorSlug.current,
    );

    chosenDirector.name = chosenDirector.nickName
      ? chosenDirector.nickName
      : chosenDirector.firstName;
  }

  return (
    <>
      <SEO
        title={`Work With ${isDirectorChosen ? chosenDirector.name : 'Us'}`}
      />
      <Helmet>
        <body className='light-nav' />
      </Helmet>
      <Section
        style={{ marginTop: 'var(--site-header-height)', overflowX: 'clip' }}
        light
      >
        <Container>
          <CenteredHeaderBlock
            data={{
              preHeader: `Work with ${
                isDirectorChosen ? chosenDirector.name : 'us'
              }`,
              title: 'Nice Choice!',
              body: `Fill out the contact form below to ${
                isDirectorChosen
                  ? `get ${chosenDirector.name} involved in`
                  : `get started on`
              } your upcoming project or brief!`,
            }}
          />
          <Spacer size={64} />
          <FormWrapper>
            <FormStripes01 direction='rightAndUp'>
              <Stripes03SVG style={{ transform: `scaleX(-1)` }} />
            </FormStripes01>
            <FormTile>
              <FormTitle>Get in touch</FormTitle>
              {isDirectorChosen && (
                <>
                  <DirectorLabel>Director</DirectorLabel>
                  <DirectorTile>
                    <DirectorHeadshot
                      image={chosenDirector.headshot.asset.gatsbyImageData}
                      alt={`${chosenDirector.name}`}
                    />
                    <DirectorName>
                      {chosenDirector.lastName
                        ? `${chosenDirector.firstName} ${chosenDirector.lastName}`
                        : chosenDirector.nickName}
                    </DirectorName>
                  </DirectorTile>
                  <Spacer size={24} />
                </>
              )}
              <Formik
                initialValues={{
                  'form-name': `Work with director`,
                  'bot-field': ``,
                  director: `${
                    isDirectorChosen ? chosenDirector.name : 'not selected'
                  }`,
                  name: ``,
                  email: ``,
                  phone: ``,
                  message: ``,
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                  formEl.current.submit();
                }}
              >
                {({
                  handleChange,
                  handleBlur,
                  handleReset,
                  values,
                  errors,
                  touched,
                  isValid,
                }) => (
                  <Form
                    name='Work With Director Form'
                    method='POST'
                    data-netlify='true'
                    data-netlify-honeypot='bot-field'
                    ref={formEl}
                    action={'/success/'}
                  >
                    {/* The `form-name` hidden field is required to support form
                submissions in Netlify */}
                    <input
                      type='hidden'
                      name='form-name'
                      value='Work With Director Form'
                    />
                    <p hidden>
                      Don’t fill this out if you're a human:
                      <input name='bot-field' />
                    </p>
                    <input
                      type='hidden'
                      name='director'
                      value={
                        isDirectorChosen && chosenDirector.firstName
                          ? chosenDirector.firstName
                          : 'No Director Selected'
                      }
                    />
                    <FormInput2
                      name='name'
                      label='Name'
                      placeholder='John'
                      required
                      value={values.name}
                      onChange={handleChange('name')}
                      onBlur={handleBlur('name')}
                      errorCase={!!errors.name && touched.name}
                      errorMsg={errors.name}
                    />
                    <Spacer size={24} />
                    <FormInput2
                      name='email'
                      label='Email'
                      placeholder='johnmurphy@gmail.com'
                      required
                      value={values.email}
                      onChange={handleChange('email')}
                      onBlur={handleBlur('email')}
                      errorCase={!!errors.email && touched.email}
                      errorMsg={errors.email}
                    />
                    <Spacer size={24} />
                    <FormInput2
                      name='phone'
                      label='Phone'
                      placeholder='0123456789'
                      required
                      value={values.phone}
                      onChange={handleChange('phone')}
                      onBlur={handleBlur('phone')}
                      errorCase={!!errors.phone && touched.phone}
                      errorMsg={errors.phone}
                    />
                    <Spacer size={24} />
                    <FormInput2
                      name='message'
                      label='Message'
                      multiline
                      value={values.message}
                      onChange={handleChange('message')}
                      onBlur={handleBlur('message')}
                      errorCase={!!errors.message && touched.message}
                      errorMsg={errors.message}
                    />
                    <Spacer size={48} />
                    <SendMessageButton
                      type='submit'
                      disabled={!isValid || !touched}
                      label='Submit'
                      arrow
                    />
                  </Form>
                )}
              </Formik>
            </FormTile>
            <FormStripes02 direction='left'>
              <Stripes03SVG style={{ transform: `scaleX(-1)` }} />
            </FormStripes02>
          </FormWrapper>
        </Container>
        <BottomCut dark />
      </Section>

      <Section dark cuts='topAndBottomCuts'>
        <Container>
          <CTABlock
            data={{
              type: 'primary',
              darkBG: true,
              title: 'Still looking for more information?',
              preHeader: 'We make video production simple',
              body: 'Just get in touch with us at our generic contact point and we can point you in the right direction and answer any questions you have.',
              primaryCTA: true,
              primaryCTAData: {
                label: 'Get in touch',
                path: '/contact',
                arrow: true,
                type: 'primary',
              },
              secondaryCTA: false,
              subPoint1: {
                content: 'employee',
                employee: data.allSanityEmployee.nodes.find(
                  (employee) => employee.email == 'eoghan@rubberduck.ie',
                ),
              },
              subPoint2: {
                content: 'employee',
                employee: data.allSanityEmployee.nodes.find(
                  (employee) => employee.email == 'helen@rubberduck.ie',
                ),
              },
            }}
          />
        </Container>
        <BottomCut dark />
      </Section>
    </>
  );
};

export const directorQuery = graphql`
  query DirectorPageQuery {
    allSanityDirector {
      nodes {
        slug {
          current
        }
        firstName
        lastName
        nickName
        relationship
        bio
        headshot {
          ...SanityImageFragment
        }
      }
    }
    allSanityEmployee {
      nodes {
        firstName
        lastName
        email
        phoneNumber
        role
        headshot {
          ...SanityImageFragment
        }
      }
    }
  }
`;

export default WorkWithDirectorPage;
